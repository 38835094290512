import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { City, Country, State } from "country-state-city";
import { Col, Form, Row } from "react-bootstrap";
import _ from "lodash";
import Select from "react-select";
import { useEffect, useState } from "react";
export default function LocationSubForm({
  parentData,
  setParentData,
  isEditMode = true,
}) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setParentData({ ...parentData, country: "IN", state: "", city: "" });
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const cpy = _.cloneDeep(parentData);
    _.set(cpy, name, value);
    setParentData(cpy);
  };
  // Prepopulate the country, state, and city based on parentData
  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );

    if (parentData.country) {
      const statesOfCountry = State.getStatesOfCountry(parentData.country);
      setStates(
        statesOfCountry.map((state) => ({
          value: state.isoCode,
          label: state.name,
        }))
      );
    }

    if (parentData.state) {
      const citiesOfState = City.getCitiesOfState(
        parentData.country,
        parentData.state
      );
      setCities(
        citiesOfState.map((city) => ({
          value: city.name,
          label: city.name,
        }))
      );
    }
  }, [parentData.country, parentData.state]);

  const handleCountryChange = (selectedOption) => {
    setParentData({
      ...parentData,
      country: selectedOption.value,
      state: "", // Reset state and city when country changes
      city: "",
    });
    setStates(State.getStatesOfCountry(selectedOption.value));
    setCities([]); // Reset city options when country changes
  };

  const handleStateChange = (selectedOption) => {
    setParentData({
      ...parentData,
      state: selectedOption.value,
      city: "", // Reset city when state changes
    });
    setCities(City.getCitiesOfState(parentData.country, selectedOption.value));
  };

  const handleCityChange = (selectedOption) => {
    setParentData({
      ...parentData,
      city: selectedOption.value,
    });
  };

  useEffect(() => {
    const countryList = Country.getAllCountries(); // Load countries on component mount
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);
  return (
    <>
      <Row>
        <Col xs={12} lg={4} className="mb-2">
          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Select
              size="small"
              isDisabled={!isEditMode}
              options={countries}
              onChange={handleCountryChange}
              value={countries.find(
                (country) => country.value === parentData.country
              )}
              placeholder="Select Country"
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={4} className="mb-2">
          <Form.Group controlId="state">
            <Form.Label>State</Form.Label>
            <Select
              options={states}
              onChange={handleStateChange}
              value={states.find((state) => state.value === parentData.state)}
              placeholder="Select State"
              isDisabled={states.length === 0 || !isEditMode} // Disable if no states are available
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={4} className="mb-2">
          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Select
              options={cities}
              onChange={handleCityChange}
              value={cities.find((city) => city.value === parentData.city)}
              placeholder="Select City"
              isDisabled={cities.length === 0 || !isEditMode} // Disable if no cities are available
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Address Section */}
      <Row className="mb-4">
        <Col>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              required
              disabled={!isEditMode}
              type="text"
              name="address"
              value={parentData.address}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
