import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function CustomToolTip({ key, label, children }) {
  return (
    <OverlayTrigger
      key={key}
      placement="top"
      overlay={<Tooltip id={`tooltip-${key}`}>{label}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
