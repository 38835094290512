import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../context/user";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomToolTip from "../custom/tooltip";

// Sign In Form Component
const SignInForm = ({
  handleAuth,
  formDetails,
  setFormDetails,
  buttonLoading,
}) => {
  const [showPassword, setShowPassword] = useState();
  return (
    <Form onSubmit={handleAuth}>
      <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={formDetails.email || ""}
          onChange={(e) =>
            setFormDetails((f) => ({ ...f, email: e.target.value }))
          }
          required
        />
      </Form.Group>

      <Form.Group controlId="formPassword" className="mt-3">
        <Form.Label>Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            value={formDetails.password || ""}
            onChange={(e) =>
              setFormDetails((f) => ({
                ...f,
                password: e.target.value,
              }))
            }
            required
          />
          <InputGroup.Text
            onClick={() => setShowPassword(!showPassword)}
            style={{
              cursor: "pointer",
              background: "transparent",
              borderLeft: "none",
            }}
          >
            <CustomToolTip label="Show" key="show">
              <Button
                className="p-0 text-decoration-none"
                variant="link"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
            </CustomToolTip>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Button
        disabled={buttonLoading}
        variant="primary"
        type="submit"
        className="w-100 mt-4"
      >
        {buttonLoading && <Spinner size="sm" />}
        <span className="p-2">{buttonLoading ? "Signing In" : "Sign In"}</span>
      </Button>
    </Form>
  );
};

// Sign Up Form Component
const SignUpForm = ({
  handleAuth,
  formDetails,
  setFormDetails,
  buttonLoading,
}) => (
  <Form onSubmit={handleAuth}>
    <Form.Group controlId="formName" className="mt-3">
      <Form.Label>Name</Form.Label>
      <Form.Control
        required
        type="text"
        value={formDetails.name || ""}
        onChange={(e) =>
          setFormDetails((f) => ({
            ...f,
            name: e.target.value,
          }))
        }
      />
    </Form.Group>
    <Form.Group controlId="formCompany" className="mt-3">
      <Form.Label>Company Name</Form.Label>
      <Form.Control
        required
        type="text"
        value={formDetails.company || ""}
        onChange={(e) =>
          setFormDetails((f) => ({
            ...f,
            company: e.target.value,
          }))
        }
      />
    </Form.Group>

    <Form.Group controlId="formEmail" className="mt-3">
      <Form.Label>Email</Form.Label>
      <Form.Control
        required
        type="email"
        value={formDetails.email || ""}
        onChange={(e) =>
          setFormDetails((f) => ({ ...f, email: e.target.value }))
        }
      />
    </Form.Group>

    <Form.Group controlId="formPassword" className="mt-3">
      <Form.Label>Password</Form.Label>
      <Form.Control
        required
        type="password"
        value={formDetails.password || ""}
        onChange={(e) =>
          setFormDetails((f) => ({
            ...f,
            password: e.target.value,
          }))
        }
      />
    </Form.Group>

    <Button
      disabled={buttonLoading}
      variant="primary"
      type="submit"
      className="w-100 mt-4"
    >
      {buttonLoading && <Spinner size="sm" />}
      <span className="p-2">{buttonLoading ? "Signing Up" : "Sign Up"}</span>
    </Button>
  </Form>
);

// Sign Up Form Component
const LightAccountForm = ({
  handleAuth,
  buttonLoading,
  formDetails,
  setFormDetails,
}) => {
  return (
    <Form onSubmit={handleAuth}>
      <Form.Group controlId="formName" className="mt-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={formDetails.name || ""}
          onChange={(e) =>
            setFormDetails((f) => ({
              ...f,
              name: e.target.value,
            }))
          }
        />
      </Form.Group>
      <Form.Group controlId="formCompany" className="mt-3">
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={formDetails.company || ""}
          onChange={(e) =>
            setFormDetails((f) => ({
              ...f,
              company: e.target.value,
            }))
          }
        />
      </Form.Group>

      <Form.Group controlId="formEmail" className="mt-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          value={formDetails.email || ""}
          onChange={(e) =>
            setFormDetails((f) => ({ ...f, email: e.target.value }))
          }
        />
      </Form.Group>

      <Form.Group controlId="message" className="mt-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          required
          as="textarea"
          value={formDetails.message || ""}
          onChange={(e) =>
            setFormDetails((f) => ({
              ...f,
              message: e.target.value,
            }))
          }
        />
      </Form.Group>
      <Button
        disabled={buttonLoading}
        variant="primary"
        type="submit"
        className="w-100 mt-4"
      >
        {buttonLoading && <Spinner size="sm" />}
        <span className="p-2">{buttonLoading ? "Submitting" : "Submit"}</span>
      </Button>
    </Form>
  );
};

const AuthForm = ({ type, callback }) => {
  const { signUp, signIn } = useAuth();
  const isSigningIn = type === "signin";
  // const [isSigningIn, setIsSigningIn] = useState(type === "signin"); // Toggle between SignIn and SignUp
  const [formDetails, setFormDetails] = useState({});
  const [error, setError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  // () => setIsSigningIn((prevState) => !prevState);
  const getParams = (keys) =>
    keys.reduce((o, k) => {
      o[k] = formDetails[k];
      return o;
    }, {});
  const handleAuth = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    setError("");

    try {
      if (isSigningIn) {
        // Sign In Logic
        const params = getParams(["email", "password"]);
        await signIn(params);
      } else {
        // Sign Up Logic
        const params = getParams(["email", "password", "company", "name"]);
        await signUp(params);
      }
      callback?.();
    } catch (error) {
      toast(error.message);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="w-100">
          <Col xs={12} md={6} lg={4} className="mx-auto w-100">
            {error && <p className="text-danger text-center">{error}</p>}
            {/* <ButtonGroup className="mb-3 w-100">
              <ToggleButton
                type="radio"
                variant={isSigningIn ? "primary" : "outline-primary"}
                name="radio"
                value={true}
                checked={isSigningIn}
                onClick={() => setIsSigningIn(true)}
              >
                Sign In
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant={!isSigningIn ? "primary" : "outline-primary"}
                name="radio"
                value={false}
                checked={!isSigningIn}
                onClick={() => setIsSigningIn(false)}
              >
                Sign Up
              </ToggleButton>
            </ButtonGroup> */}
            {isSigningIn ? (
              <SignInForm
                handleAuth={handleAuth}
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                buttonLoading={buttonLoading}
              />
            ) : (
              <SignUpForm
                handleAuth={handleAuth}
                formDetails={formDetails}
                setFormDetails={setFormDetails}
                buttonLoading={buttonLoading}
              />
            )}
            {/* <div className="text-center mt-3">
              <Button variant="link" onClick={toggleForm}>
                {isSigningIn
                  ? "Don't have an account? Sign Up"
                  : "Already have an account? Sign In"}
              </Button>
            </div> */}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AuthForm;
export { LightAccountForm };
