import { useState } from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useData } from "../context/data";
import { useAuth } from "../context/user";
import InvoiceForm from "./form";
import "./index.css";
import InvoicePreview from "./preview";
import InvoiceTable from "./table";
import { createOrUpdate } from "../utils/firebase";
import { stateUpdator } from "../utils/common";

export default function InvoiceIndex() {
  const { currentUser } = useAuth();
  const { dataLoader } = useData();
  const { invoices, setInvoices } = useData();

  const [open, setOpen] = useState("");
  const initialInvoiceData = {
    purchaseOrder: {
      date: "",
      number: "",
    },
    bank: {
      name: "",
      ifsc: "",
      accountNo: "",
    },
    number: "",
    termsAndConditions: [],
    date: "",
    totalInWords: "",
    totalBeforeTax: "",
    totalCGST: "",
    totalAmount: 0,
    customer: {},
    totalSGST: "",
    items: [
      {
        hsn: "",
        description: "",
        total: "",
      },
    ],
  };
  const [selected, setSelected] = useState(initialInvoiceData);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!currentUser?.id) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line
  // }, [currentUser]);

  return !currentUser?.id ? (
    <></>
  ) : dataLoader ? (
    <div
      style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      <Container className="mt-2">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="text-center mb-0">Invoices</h3>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              setSelected(initialInvoiceData);
              setOpen("form");
            }}
          >
            Add New Invoice
          </Button>
        </div>
        <InvoiceTable
          invoices={invoices}
          setOpen={setOpen}
          setSelected={setSelected}
          onEdit={(invoice) => {
            setOpen("form");
            setSelected(invoice);
          }}
          actions={[
            {
              label: "Print",
              key: "print",
              icon: <FaPrint />,
              onClick: (invoice) => {
                setOpen("preview");
                setSelected(invoice);
              },
            },
          ]}
        />
        <ToastContainer />
      </Container>
      <Modal
        show={open !== ""}
        onHide={() => setOpen("")}
        size={open === "nextAction" ? "md" : "xl"}
        fullscreen="lg-down"
      >
        <Modal.Body>
          <>
            {open === "form" ? (
              <InvoiceForm
                selected={selected}
                nextStep={(invoiceData, setLoading) => {
                  setLoading(true);
                  createOrUpdate("invoice", {
                    company_id: currentUser.company_id,
                    ...invoiceData,
                  }).then((obj) => {
                    stateUpdator(selected?.id, obj, setInvoices);
                    setLoading(false);
                    if (!selected?.id) {
                      setOpen("nextAction");
                      setSelected(obj);
                    } else {
                      toast(`Invoice edited successfully`);
                      setOpen("");
                    }
                  });
                }}
                open={open}
                setOpen={setOpen}
              />
            ) : open === "preview" ? (
              <>
                <InvoicePreview
                  invoiceData={selected}
                  currentUser={currentUser}
                />
              </>
            ) : open === "nextAction" ? (
              <div className="d-flex gap-3 flex-column text-center">
                <h4>Invoice {selected.number} has been created.</h4>
                <Container className="d-flex gap-2 flex-column">
                  <Button
                    onClick={() => {
                      setSelected(selected);
                      setOpen("preview");
                    }}
                  >
                    Print this invoice
                  </Button>
                  <Button
                    onClick={() => {
                      setSelected(initialInvoiceData);
                      setOpen("form");
                    }}
                  >
                    Create a new one
                  </Button>
                </Container>
              </div>
            ) : (
              <></>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
