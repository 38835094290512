import { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import { useData } from "../context/data";
import { useAuth } from "../context/user";
import InvoiceForm from "../invoice/form";
import { stateUpdator } from "../utils/common";
import { createOrUpdate } from "../utils/firebase";
import FormStep from "./form";
import "./index.css";

import { FaPrint, FaStickyNote } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../custom/button";
import InvoicePreview from "../invoice/preview";
import InvoiceTable from "../invoice/table";
import CustomerTable from "./table";
// import { useNavigate } from "react-router-dom";
export default function CustomerIndex() {
  const { customers, setCustomers, dataLoader } = useData();
  const { currentUser } = useAuth();
  const [selected, setSelected] = useState();
  const [openForm, setOpenForm] = useState();

  const [open, setOpen] = useState("");

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!currentUser?.id) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line
  // }, [currentUser]);
  return !currentUser?.id ? (
    <></>
  ) : dataLoader ? (
    <div
      style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Container className="mt-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="text-center mb-0">Customers</h3>
        <Button
          size="sm"
          variant="success"
          onClick={() => {
            setSelected({});
            setOpen("form");
          }}
        >
          Add New Customer
        </Button>
      </div>
      <CustomerTable
        customers={customers}
        onEdit={(customer) => {
          setOpen("form");
          setSelected(customer);
        }}
        actions={[
          {
            label: "Invoices",
            key: "invoices",
            icon: <FaStickyNote />,
            onClick: (customer) => {
              setOpen("invoices");
              setOpenForm(false);
              setSelected(customer);
            },
          },
        ]}
      />
      <ToastContainer />
      <Modal
        show={["form", "nextAction"].includes(open)}
        onHide={() => setOpen("")}
        size={open === "nextAction" ? "md" : "xl"}
        fullscreen="lg-down"
      >
        <Modal.Body>
          {open === "form" ? (
            <CustomerForm
              selected={selected}
              open={open}
              setOpen={setOpen}
              nextStep={(cust, setLoading) => {
                setLoading(true);
                createOrUpdate("customer", {
                  company_id: currentUser.company_id,
                  ...cust,
                }).then((obj) => {
                  stateUpdator(selected?.id, obj, setCustomers);
                  setLoading(false);
                  if (!selected?.id) {
                    toast(`Customer added successfully`);
                  } else {
                    toast(`Customer edited successfully`);
                  }
                  setOpen("");
                });
              }}
            />
          ) : open === "nextAction" ? (
            <div className="d-flex gap-3 flex-column text-center">
              <h4>Customer {selected.name} has been created.</h4>
              <Container className="d-flex gap-2 flex-column">
                <Button
                  onClick={() => {
                    setOpen("invoices");
                    setOpenForm(true);
                  }}
                >
                  Create an invoice against the customer
                </Button>
                <Button
                  onClick={() => {
                    setSelected();
                    setOpen("form");
                  }}
                >
                  Create a new one
                </Button>
              </Container>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      {selected?.id && (
        <Modal
          show={open === "invoices"}
          onHide={() => setOpen("")}
          size={open === "nextAction" ? "md" : "xl"}
          fullscreen="lg-down"
        >
          <Modal.Body>
            <CustomerInvoice
              selected={selected}
              setOpen={setOpen}
              defaultView={openForm ? "form" : "table"}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

function CustomerForm({ nextStep, setOpen, selected }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    name: "",
    address: "",
    email: "",
    mobile: "",
    gstin: "",
    city: "",
    state: "",
    country: "IN",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep(customerData, setLoading);
  };
  useEffect(() => {
    if (selected?.id) {
      setCustomerData(selected);
    } else {
      setIsEditMode(true);
    }
  }, [selected]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="mb-2 d-flex align-items-center">
          <h4> {selected?.id ? "Edit" : "Add New"} Customer</h4>
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setOpen("")}
        ></button>
      </div>
      <Form onSubmit={handleSubmit}>
        <FormStep
          isEditMode={isEditMode}
          setCustomerData={setCustomerData}
          customerData={customerData}
        />
        {isEditMode ? (
          <div className="d-flex justify-content-end gap-4">
            {selected?.id && (
              <Button variant="secondary" onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
            )}
            <CustomButton
              variant="primary"
              type="submit"
              className="float-end"
              loading={loading}
            >
              {loading ? "Submitting" : "Submit"}
            </CustomButton>
          </div>
        ) : (
          <div className="d-flex gap-4 float-end">
            <Button variant="primary" onClick={() => setIsEditMode(true)}>
              Edit
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}

function CustomerInvoice({ selected, setOpen, defaultView }) {
  const { invoices, setInvoices } = useData();
  const { currentUser } = useAuth();

  const [view, setView] = useState("table");
  useEffect(() => {
    if (!defaultView) return;
    setView(defaultView);
  }, [defaultView]);
  const initialInvoiceData = {
    purchaseOrder: {
      date: "",
      number: "",
    },
    bank: {
      name: "",
      ifsc: "",
      accountNo: "",
    },
    number: "",
    termsAndConditions: [],
    date: "",
    totalInWords: "",
    totalBeforeTax: "",
    totalCGST: "",
    totalAmount: 0,
    customer: selected,
    totalSGST: "",
    items: [
      {
        hsn: "",
        description: "",
        total: "",
      },
    ],
  };

  const [selectedInvoice, setSelectedInvoice] = useState(initialInvoiceData);

  return view === "table" ? (
    <div>
      <div className="d-flex justify-content-between mt-2">
        <h4>Invoices for {selected.name}</h4>

        <div className="mb-2 d-flex align-items-center gap-2">
          <Button variant="success" onClick={() => setView("form")} size="sm">
            Add New Invoice
          </Button>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setOpen("")}
          ></button>
        </div>
      </div>
      <InvoiceTable
        invoices={invoices.filter((inv) => inv.customer.id === selected.id)}
        selectedCustomer={selected}
        onEdit={(invoice) => {
          setSelectedInvoice(invoice);
          setView("form");
        }}
        actions={[
          {
            label: "Print",
            icon: <FaPrint />,
            onClick: (invoice) => {
              setSelectedInvoice(invoice);
              setView("preview");
            },
          },
        ]}
      />
    </div>
  ) : (
    <div>
      <div className="d-flex justify-content-between">
        <div className="mb-2 d-flex align-items-center">
          {view === "preview" ? (
            <div className="d-flex align-items-center justify-space-between">
              <Button
                variant="link"
                className="p-0 text-decoration-none"
                onClick={() => {
                  setView("table");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="back-icon"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </Button>
              <h4 className="mb-0">
                {" "}
                {selectedInvoice?.number} - Download Preview
              </h4>
            </div>
          ) : view === "form" ? (
            <div className="d-flex align-items-center justify-space-between">
              <Button
                variant="link"
                className="p-0 text-decoration-none"
                onClick={() => {
                  setView("table");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="back-icon"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </Button>
              <h4 className="mb-0">
                {selected.name} - {selectedInvoice?.id ? "Edit" : "Add New"}{" "}
                Invoice
              </h4>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setOpen("")}
        ></button>
      </div>
      {view === "form" ? (
        <InvoiceForm
          selected={selectedInvoice}
          nextStep={(invoiceData, setLoading) => {
            setLoading(true);
            createOrUpdate("invoice", {
              company_id: currentUser.company_id,
              ...invoiceData,
            }).then((obj) => {
              stateUpdator(selectedInvoice?.id, obj, setInvoices);
              setLoading(false);
              if (!selectedInvoice?.id) {
                setView("nextAction");
                setSelectedInvoice(obj);
              } else {
                toast(`Invoice edited successfully`);
                setOpen("");
              }
            });
          }}
        />
      ) : view === "nextAction" ? (
        <div className="d-flex gap-3 flex-column text-center">
          <h4>Invoice {selectedInvoice.number} has been created.</h4>
          <Container className="d-flex gap-2 flex-column">
            <Button
              onClick={() => {
                setSelectedInvoice(selectedInvoice);
                setView("preview");
              }}
            >
              Print this invoice
            </Button>
            <Button
              onClick={() => {
                setSelectedInvoice(initialInvoiceData);
                setView("form");
              }}
            >
              Create a new one
            </Button>
          </Container>
        </div>
      ) : (
        <InvoicePreview
          invoiceData={selectedInvoice}
          currentUser={currentUser}
        />
      )}
      {/* <InvoiceForm
        selected={selectedInvoice}
        formBackBtnAction={() => {
          setView("table");
          setSelectedInvoice(initialInvoiceData);
        }}
      /> */}
    </div>
  );
}
