import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LocationSubForm from "../custom/locationSubForm";
import { getGSTPattern } from "../utils/common";

// Step 1: Form Step for entering the Invoice Details
export default function FormStep({
  isEditMode,
  customerData,
  setCustomerData,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevState) => {
      const cpy = _.cloneDeep(prevState);
      _.set(cpy, name, value);
      return cpy;
    });
  };
  return (
    <>
      <Row>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="name">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              required
              disabled={!isEditMode}
              type="text"
              name="name"
              value={customerData.name}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              disabled={!isEditMode}
              type="email"
              name="email"
              value={customerData.email}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              required
              disabled={!isEditMode}
              type="text"
              name="mobile"
              value={customerData.mobile}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="gstin">
            <Form.Label>GSTIN</Form.Label>
            <Form.Control
              required
              disabled={!isEditMode}
              type="text"
              name="gstin"
              pattern={getGSTPattern(customerData.state)}
              title="Please enter valid GST"
              value={customerData.gstin}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <LocationSubForm
        parentData={customerData}
        setParentData={setCustomerData}
        isEditMode={isEditMode}
      />
    </>
  );
}
