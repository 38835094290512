import React from "react";
import { Form } from "react-bootstrap";
import { formatCurrency } from "../utils/common";

const LineItemField = ({ field, item, index, onChange, ...props }) => {
  const formatField = () => {
    switch (field.type) {
      case "currency":
        return (
          <div className="input-group">
            <span className="input-group-text" style={{ padding: "0 0.75rem" }}>
              ₹
            </span>
            <Form.Control required
              size="sm"
              {...props}
              type="number"
              step="0.01"
              name={field.key}
              value={item[field.key]}
              onChange={(e) => onChange(e, index)}
            />
          </div>
        );
      case "percentage":
        return (
          <div className="input-group">
            <Form.Control required
              {...props}
              size="sm"
              type="number"
              step="0.01"
              name={field.key}
              value={item[field.key]}
              onChange={(e) => onChange(e, index)}
            />
            <span className="input-group-text" style={{ padding: "0 0.75rem" }}>
              %
            </span>
          </div>
        );
      case "number":
        return (
          <Form.Control required
            {...props}
            size="sm"
            type="number"
            step="0.001"
            name={field.key}
            value={item[field.key]}
            onChange={(e) => onChange(e, index)}
          />
        );
      case "text":
        return (
          <Form.Control required
            {...props}
            size="sm"
            type="text"
            name={field.key}
            value={item[field.key]}
            onChange={(e) => onChange(e, index)}
          />
        );
      case "currency_text":
        return formatCurrency(parseFloat(item[field.key]));
      case "percentage_text":
        return `${item[field.key]} %`;
      default:
        return item[field.key];
    }
  };

  return <>{formatField()}</>;
};

export default LineItemField;
