import { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../context/user";
import { stateUpdator } from "../utils/common";
import { createOrUpdate } from "../utils/firebase";
import FormStep from "./form";
import "./index.css";

import { FaTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCsaData } from "../context/csa";
import CustomButton from "../custom/button";
import { auth } from "../firebase-config";
import CompanyTable from "./table";
export default function CompanyIndex() {
  const { companies, setCompanies, dataLoader } = useCsaData();
  const { currentUser, createUserWithoutAffectingSession } = useAuth();

  const [selected, setSelected] = useState();

  // const [openForm, setOpenForm] = useState();

  const [open, setOpen] = useState("");

  return !currentUser?.id ? (
    <></>
  ) : dataLoader ? (
    <div
      style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Container className="mt-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="text-center mb-0">Companies</h3>
        <Button
          size="sm"
          variant="success"
          onClick={() => {
            setSelected({});
            setOpen("form");
          }}
        >
          Add New Company
        </Button>
      </div>
      <CompanyTable
        companies={companies}
        onEdit={(company) => {
          setOpen("form");
          setSelected(company);
        }}
        actions={[
          {
            label: "Deactivate",
            key: "deactivate",
            icon: <FaTrashAlt />,
            onClick: (company) => {
              // setOpen("invoices");
              // setOpenForm(false);
              // setSelected(company);
            },
          },
        ]}
      />
      <ToastContainer />
      <Modal
        show={open === "form"}
        onHide={() => setOpen("")}
        size={open === "nextAction" ? "md" : "xl"}
        fullscreen="lg-down"
      >
        <Modal.Body>
          {open === "form" ? (
            <CompanyForm
              selected={selected}
              open={open}
              setOpen={setOpen}
              nextStep={(cust, setLoading) => {
                setLoading(true);

                createUserWithoutAffectingSession(
                  auth,
                  cust.email,
                  "test@123"
                ).then((user) => {
                  createOrUpdate("company", {
                    ...cust,
                    owner: { ...cust.owner, id: user.uid },
                  }).then((obj) => {
                    stateUpdator(selected?.id, obj, setCompanies);
                    setLoading(false);
                    if (!selected?.id) {
                      toast(`Company added successfully`);
                    } else {
                      toast(`Company edited successfully`);
                    }
                    setOpen("");
                  });
                });
              }}
            />
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      {/* {selected?.id && (
        <Modal
          show={open === "invoices"}
          onHide={() => setOpen("")}
          size={open === "nextAction" ? "md" : "xl"}
          fullscreen="lg-down"
        >
          <Modal.Body>
            <CompanyInvoice
              selected={selected}
              setOpen={setOpen}
              defaultView={openForm ? "form" : "table"}
            />
          </Modal.Body>
        </Modal>
      )} */}
    </Container>
  );
}

function CompanyForm({ nextStep, setOpen, selected }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: "",
    email: "",
    mobile: "",
    gstin: "",
    country: "",
    state: "",
    city: "",
    address: "",
    bank: {
      accountNumber: "",
      ifscCode: "",
      bankName: "",
    },
    terms: [],
    owner: { name: "" },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep(companyData, setLoading);
  };
  useEffect(() => {
    if (selected?.id) {
      setCompanyData(selected);
    } else {
      setIsEditMode(true);
    }
  }, [selected]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="mb-2 d-flex align-items-center">
          <h4> {selected?.id ? "Edit" : "Add New"} Company</h4>
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setOpen("")}
        ></button>
      </div>
      <Form onSubmit={handleSubmit}>
        <FormStep
          isEditMode={isEditMode}
          setCompanyData={setCompanyData}
          companyData={companyData}
        />
        {isEditMode ? (
          <div className="d-flex justify-content-end gap-4">
            {selected?.id && (
              <Button variant="secondary" onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
            )}
            <CustomButton
              variant="primary"
              type="submit"
              className="float-end"
              loading={loading}
            >
              {loading ? "Submitting" : "Submit"}
            </CustomButton>
          </div>
        ) : (
          <div className="d-flex gap-4 float-end">
            <Button variant="primary" onClick={() => setIsEditMode(true)}>
              Edit
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}

// function CompanyInvoice({ selected, setOpen, defaultView }) {
//   const { invoices, setInvoices } = useData();
//   const { currentUser } = useAuth();

//   const [view, setView] = useState("table");
//   useEffect(() => {
//     if (!defaultView) return;
//     setView(defaultView);
//   }, [defaultView]);
//   const initialInvoiceData = {
//     purchaseOrder: {
//       date: "",
//       number: "",
//     },
//     bank: {
//       name: "",
//       ifsc: "",
//       accountNo: "",
//     },
//     number: "",
//     termsAndConditions: [],
//     date: "",
//     totalInWords: "",
//     totalBeforeTax: "",
//     totalCGST: "",
//     totalAmount: 0,
//     company: selected,
//     totalSGST: "",
//     items: [
//       {
//         hsn: "",
//         description: "",
//         total: "",
//       },
//     ],
//   };

//   const [selectedInvoice, setSelectedInvoice] = useState(initialInvoiceData);

//   return view === "table" ? (
//     <div>
//       <div className="d-flex justify-content-between mt-2">
//         <h4>Invoices for {selected.name}</h4>

//         <div className="mb-2 d-flex align-items-center gap-2">
//           <Button variant="success" onClick={() => setView("form")} size="sm">
//             Add New Invoice
//           </Button>
//           <button
//             type="button"
//             className="btn-close"
//             aria-label="Close"
//             onClick={() => setOpen("")}
//           ></button>
//         </div>
//       </div>
//       <InvoiceTable
//         invoices={invoices.filter((inv) => inv.company.id === selected.id)}
//         selectedCompany={selected}
//         onEdit={(invoice) => {
//           setSelectedInvoice(invoice);
//           setView("form");
//         }}
//         actions={[
//           {
//             label: "Print",
//             icon: <FaPrint />,
//             onClick: (invoice) => {
//               setSelectedInvoice(invoice);
//               setView("preview");
//             },
//           },
//         ]}
//       />
//     </div>
//   ) : (
//     <div>
//       <div className="d-flex justify-content-between">
//         <div className="mb-2 d-flex align-items-center">
//           {view === "preview" ? (
//             <div className="d-flex align-items-center justify-space-between">
//               <Button
//                 variant="link"
//                 className="p-0 text-decoration-none"
//                 onClick={() => {
//                   setView("table");
//                 }}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="20"
//                   height="20"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                   stroke="#000"
//                   stroke-width="2"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   class="back-icon"
//                 >
//                   <polyline points="15 18 9 12 15 6"></polyline>
//                 </svg>
//               </Button>
//               <h4 className="mb-0">
//                 {" "}
//                 {selectedInvoice?.number} - Download Preview
//               </h4>
//             </div>
//           ) : view === "form" ? (
//             <div className="d-flex align-items-center justify-space-between">
//               <Button
//                 variant="link"
//                 className="p-0 text-decoration-none"
//                 onClick={() => {
//                   setView("table");
//                 }}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="20"
//                   height="20"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                   stroke="#000"
//                   stroke-width="2"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   class="back-icon"
//                 >
//                   <polyline points="15 18 9 12 15 6"></polyline>
//                 </svg>
//               </Button>
//               <h4 className="mb-0">
//                 {selected.name} - {selectedInvoice?.id ? "Edit" : "Add New"}{" "}
//                 Invoice
//               </h4>
//             </div>
//           ) : (
//             <div></div>
//           )}
//         </div>
//         <button
//           type="button"
//           className="btn-close"
//           aria-label="Close"
//           onClick={() => setOpen("")}
//         ></button>
//       </div>
//       {view === "form" ? (
//         <InvoiceForm
//           selected={selectedInvoice}
//           nextStep={(invoiceData, setLoading) => {
//             setLoading(true);
//             createOrUpdate("invoice", {
//               company_id: currentUser.company_id,
//               ...invoiceData,
//             }).then((obj) => {
//               stateUpdator(selectedInvoice?.id, obj, setInvoices);
//               setLoading(false);
//               if (!selectedInvoice?.id) {
//                 setView("nextAction");
//                 setSelectedInvoice(obj);
//               } else {
//                 toast(`Invoice edited successfully`);
//                 setOpen("");
//               }
//             });
//           }}
//         />
//       ) : view === "nextAction" ? (
//         <div className="d-flex gap-3 flex-column text-center">
//           <h4>Invoice {selectedInvoice.number} has been created.</h4>
//           <Container className="d-flex gap-2 flex-column">
//             <Button
//               onClick={() => {
//                 setSelectedInvoice(selectedInvoice);
//                 setView("preview");
//               }}
//             >
//               Print this invoice
//             </Button>
//             <Button
//               onClick={() => {
//                 setSelectedInvoice(initialInvoiceData);
//                 setView("form");
//               }}
//             >
//               Create a new one
//             </Button>
//           </Container>
//         </div>
//       ) : (
//         <InvoicePreview
//           invoiceData={selectedInvoice}
//           currentUser={currentUser}
//         />
//       )}
//       {/* <InvoiceForm
//         selected={selectedInvoice}
//         formBackBtnAction={() => {
//           setView("table");
//           setSelectedInvoice(initialInvoiceData);
//         }}
//       /> */}
//     </div>
//   );
// }
