import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { dateFormat } from "../utils/common";
import { invoiceLineItemfields as fields } from "../utils/iterators";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v18/UcCm3FwrK3iLTcvnUwkT9nA2.woff2",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: 30,
    fontSize: 10, // Set font size to 10
  },
  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  alignRight: { textAlign: "right" },
  section: {
    marginBottom: 0,
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionPart: { width: "50%", padding: "0px 10px" },
  leftColumn: {
    width: "50%",
    padding: "0px 10px",
  },
  rightColumn: {
    width: "50%",
    padding: "0px 10px",
    textAlign: "right", // Align the text to the right for the right column
  },
  header: {
    borderBottom: "1px solid black",
    marginBottom: 0,
    paddingBottom: 0,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    margin: "5px 0px",
  },
  companyDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  companyInfo: {
    flex: 1,
    marginLeft: 10, // Space between logo and company info
  },
  logo: {
    width: 60, // Adjust logo size
    height: 60,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottom: 1,
    marginTop: 10,
    marginBottom: 5,
    paddingBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    marginBottom: 5,
    padding: "5px 0",
  },
  tableCol: {
    width: "15%",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
  descriptionCol: {
    width: "35%",
  },
  textWithPadding: {
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const InvoicePage = ({ type, invoiceData, currentUser }) => (
  <Page size="A4" style={styles.page}>
    {/* Invoice Header */}
    <View style={styles.header}>
      <Text style={{ ...styles.title, ...styles.boldText }}>TAX INVOICE</Text>
      <Text style={{ position: "absolute", top: 0, right: 0 }}>({type})</Text>
    </View>

    {/* Company Info and Logo */}
    <View style={{ ...styles.companyDetails, ...styles.section }}>
      {/* Logo */}

      <View style={{ padding: "0px 10px" }}>
        <Image
          style={styles.logo}
          src="https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg" // Add your actual logo here
        />
      </View>
      {/* Company Name and Address */}
      <View style={{ ...styles.companyInfo, padding: "0px 10px" }}>
        <Text style={styles.textWithPadding}>{currentUser.company.name}</Text>
        <Text style={styles.textWithPadding}>
          {currentUser.company.address}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Mob No: </Text>
          {currentUser.company.mobile}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Email: </Text>
          {currentUser.company.email}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>GSTIN: </Text>
          {currentUser.company.gstin}
        </Text>
      </View>
    </View>

    {/* Invoice Number and Purchase Order */}
    <View style={styles.section}>
      <View style={styles.sectionPart}>
        <Text style={{ ...styles.textWithPadding, ...styles.boldText }}>
          Invoice Number:
        </Text>
        <Text style={styles.textWithPadding}>
          {invoiceData.number} Dt. {dateFormat(invoiceData.date)}
        </Text>
      </View>

      <View style={styles.sectionPart}>
        <Text style={{ ...styles.textWithPadding, ...styles.boldText }}>
          Purchase Order:
        </Text>
        <Text style={styles.textWithPadding}>
          {invoiceData.purchaseOrder.number} Dt.{" "}
          {dateFormat(invoiceData.purchaseOrder.date)}
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.sectionPart}>
        <Text style={styles.boldText}>Bill To:</Text>
        <Text style={styles.textWithPadding}>{invoiceData.customer.name}</Text>
        <Text style={styles.textWithPadding}>
          {invoiceData.customer.address}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Mob No: </Text>
          {invoiceData.customer.mobile}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Email: </Text>
          {invoiceData.customer.email}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>GSTIN: </Text>
          {invoiceData.customer.gstin}
        </Text>
      </View>
      <View style={styles.sectionPart}>
        <Text style={styles.boldText}>Ship To:</Text>
        <Text style={styles.textWithPadding}>{invoiceData.customer.name}</Text>
        <Text style={styles.textWithPadding}>{invoiceData.shipTo.address}</Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Mob No: </Text>
          {invoiceData.shipTo.mobile}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Email: </Text>
          {invoiceData.shipTo.email}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>GSTIN: </Text>{" "}
          {invoiceData.shipTo.gstin}
        </Text>
      </View>
    </View>

    {/* Table Header */}
    <View style={{ ...styles.tableHeader, ...styles.boldText }}>
      <Text style={{ ...styles.tableCol, ...styles.alignCenter }}>S.No</Text>
      {fields.map((field, index) => (
        <Text
          key={field.key}
          style={{
            ...styles.tableCol,
            ...(field.key === "description"
              ? styles.alignLeft
              : field.type.includes("currency")
              ? styles.alignRight
              : styles.alignCenter), // Align currency fields to the right, description to left, others to center
          }}
        >
          {field.value}
        </Text>
      ))}
      <Text style={{ ...styles.tableCol, ...styles.alignCenter }}>
        Total After Tax
      </Text>
    </View>

    {/* Table Rows */}
    {invoiceData.items.map((item, index) => (
      <View key={index} style={styles.tableRow}>
        {/* S.No */}
        <Text style={{ ...styles.tableCol, ...styles.alignCenter }}>
          {index + 1}
        </Text>
        {fields.map((field) => (
          <Text
            key={field.key}
            style={{
              ...styles.tableCol,
              ...(field.key === "description"
                ? styles.alignLeft
                : field.type.includes("currency")
                ? styles.alignRight
                : styles.alignCenter), // Align currency fields to the right, description to left, others to center
            }}
          >
            {field.type.includes("currency")
              ? `Rs ${item[field.key]}`
              : field.type.includes("percentage")
              ? `${item[field.key]}%`
              : item[field.key]}
          </Text>
        ))}
        <Text style={{ ...styles.tableCol, ...styles.alignCenter }}>
          Rs {item.total_after_tax}
        </Text>
      </View>
    ))}
    <View style={styles.section}>
      <View style={styles.sectionPart}>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Bank Name: </Text>
          {currentUser.company.bank.name}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Account No: </Text>
          {currentUser.company.bank.accountNumber}
        </Text>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Bank IFSC: </Text>
          {currentUser.company.bank.ifscCode}
        </Text>
        <Text style={{ ...styles.textWithPadding, ...styles.boldText }}>
          Total Invoice Amount (in words):
        </Text>
        <Text style={styles.textWithPadding}>{invoiceData.totalInWords}</Text>
        <Text style={styles.textWithPadding}>
          Amount of tax subject to Reverse charge
        </Text>
        <Text style={{ ...styles.textWithPadding, ...styles.boldText }}>
          Terms and Conditions:
        </Text>
        {currentUser.company.terms.map((term, index) => (
          <Text style={styles.textWithPadding} key={index}>{`${
            index + 1
          }. ${term}`}</Text>
        ))}
      </View>
      <View style={styles.sectionPart}>
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Total before tax: </Text>
          Rs {invoiceData.totalBeforeTax}
        </Text>
        {invoiceData.customer.state === currentUser.company.state ? (
          <>
            <Text style={styles.textWithPadding}>
              <Text style={styles.boldText}>Total SGST: </Text>
              Rs {invoiceData.totalSGST}
            </Text>
            <Text style={styles.textWithPadding}>
              <Text style={styles.boldText}>Total CGST: </Text>
              Rs {invoiceData.totalCGST}
            </Text>
          </>
        ) : (
          <Text style={styles.textWithPadding}>
            <Text style={styles.boldText}>Total IGST: </Text>
            Rs {invoiceData.totalIGST}
          </Text>
        )}
        <Text style={styles.textWithPadding}>
          <Text style={styles.boldText}>Invoice Total: </Text>Rs{" "}
          {invoiceData.totalAmount}
        </Text>
        <Text style={{ height: 50 }}></Text>
        <Text style={[styles.certifiedText, styles.textWithPadding]}>
          Certified that the particulars given above are true and correct
        </Text>
        <Text style={styles.signatureText}>For NEXIF TECHNOLOGIES</Text>
        <Text style={{ height: 50 }}></Text>
        <Text style={styles.authorizedSignatory}>Authorised Signatory</Text>
      </View>
    </View>
  </Page>
);

const InvoicePreview = ({ ...props }) => {
  try {
    return (
      <PDFViewer width="100%" height="500" className="mb-4">
        <Document>
          <InvoicePage type="Original Copy" {...props} />
          <InvoicePage type="Duplicate Copy" {...props} />
          <InvoicePage type="Triplicate Copy" {...props} />
        </Document>
      </PDFViewer>
    );
  } catch (error) {
    console.error("PDF render error:", error);
    return "Error rendering PDF. Please try again.";
  }
};

export default InvoicePreview;
