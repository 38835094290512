import {
  Button,
  OverlayTrigger,
  Pagination,
  Table,
  Tooltip,
} from "react-bootstrap";
import { dateFormat, formatCurrency } from "../utils/common";
import { useState } from "react";
const ITEMS_PER_PAGE = 10;
export default function InvoiceTable({
  invoices,
  actions = [],
  onEdit,
  selectedCustomer,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(invoices.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginated = invoices.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  return (
    <div>
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Invoice Date</th>
            {!selectedCustomer && <th>Customer</th>}
            <th>Total Amount</th>
            {actions.length !== 0 && <th className="text-center">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {paginated.length > 0 ? (
            paginated.map((invoice, index) => (
              <tr key={index}>
                <td>
                  {onEdit ? (
                    <Button
                      variant="link"
                      style={{ textDecoration: "none" }}
                      size="sm"
                      onClick={() => onEdit(invoice)}
                    >
                      {invoice.number}
                    </Button>
                  ) : (
                    invoice.number
                  )}
                </td>
                <td>{dateFormat(invoice.date)}</td>
                {!selectedCustomer && <td>{invoice.customer.name}</td>}
                <td>{formatCurrency(parseFloat(invoice.totalAmount))}</td>
                {actions.length !== 0 && (
                  <td className="text-center">
                    {actions.map((action) => (
                      <OverlayTrigger
                        key={action.key}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${action.key}`}>
                            {action.label}
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => action.onClick(invoice)}
                        >
                          {action.icon}
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No invoices available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}
