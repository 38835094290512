import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCNsM6a6njuQvJxPOp3V5CGfegBsvIPioI",
  authDomain: "invoicingsoftware-9a97d.firebaseapp.com",
  projectId: "invoicingsoftware-9a97d",
  storageBucket: "invoicingsoftware-9a97d.appspot.com",
  messagingSenderId: "355631765357",
  appId: "1:355631765357:web:8c3eca9ce5e0c13e53ec64",
  measurementId: "G-T1V03CHFF1",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
export { auth, db };
