import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Select from "react-select";
import { useData } from "../context/data";
import { useAuth } from "../context/user";
import CustomButton from "../custom/button";
import LocationSubForm from "../custom/locationSubForm";
import {
  dateFormat,
  formatCurrency,
  getGSTPattern,
  numberToWords,
} from "../utils/common";
import { createOrUpdate } from "../utils/firebase";
import { invoiceLineItemfields as fields } from "../utils/iterators";
import "./index.css";
import LineItemField from "./lineItemField";

function AddNewShippingAddress({
  setAddNewShippingAddress,
  shippingAddresses,
  setShippingAddresses,
  setInvoiceData,
}) {
  const [shippingAddress, setShippingAddress] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    const cpy = _.cloneDeep(shippingAddress);
    _.set(cpy, name, value);
    setShippingAddress(cpy);
  };
  return (
    <>
      <Row>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="name"
              value={shippingAddress.name}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              value={shippingAddress.email}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              required
              type="text"
              name="mobile"
              value={shippingAddress.mobile}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-2">
          <Form.Group controlId="gstin">
            <Form.Label>GSTIN</Form.Label>
            <Form.Control
              required
              type="text"
              name="gstin"
              pattern={getGSTPattern(shippingAddress.state)}
              title="Please enter valid GST"
              value={shippingAddress.gstin}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <LocationSubForm
        parentData={shippingAddress}
        setParentData={setShippingAddress}
      />
      <div className="d-flex justify-content-between">
        <Button
          variant="primary"
          onClick={() => setAddNewShippingAddress(false)}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setInvoiceData((invData) => ({
              ...invData,
              shipTo: { id: shippingAddresses.length, ...shippingAddress },
            }));
            setAddNewShippingAddress(false);
            setShippingAddresses((addresses) => [
              ...addresses,
              { id: addresses.length, ...shippingAddress },
            ]);
          }}
        >
          Add the address
        </Button>
      </div>
    </>
  );
}

export default function InvoiceForm({ selected, nextStep, open, setOpen }) {
  const { customers, invoices, setCustomers } = useData();
  const { currentUser } = useAuth();

  const initialInvoiceData = {
    number: "",
    date: moment().format("YYYY-MM-DD"),
    purchaseOrder: { number: "", date: "" },
    customer: {
      name: "",
      address: "",
      phone: "",
      email: "",
    },
    shipTo: {
      name: "",
      address: "",
      phone: "",
      email: "",
    },
    items: [
      fields.reduce((o, f) => {
        o[f.key] = "";
        return o;
      }, {}),
    ],
    bank: {
      name: "",
      accountNo: "",
      ifsc: "",
    },
    totalBeforeTax: "",
    totalSGST: "",
    totalCGST: "",
    totalAmount: "",
    totalInWords: "",
    termsAndConditions: [],
  };
  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [addNewShippingAddress, setAddNewShippingAddress] = useState();

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (shippingAddresses?.length === 0) return;
    let customer = customers.find((c) => c.id === invoiceData.customer?.id);
    customer.shippingAddresses = shippingAddresses;
    setCustomers((customers) => {
      return customers.map((c) => {
        return c.id === invoiceData.customer?.id
          ? { ...c, shippingAddresses }
          : c;
      });
    });
    createOrUpdate("customer", {
      company_id: currentUser.company_id,
      ...customer,
    });
    // eslint-disable-next-line
  }, [shippingAddresses, invoiceData]);

  useEffect(() => {
    if (selected?.id) {
      setInvoiceData(selected);
    } else {
      setIsEditMode(true);
      const yymm = dateFormat(moment.now(), "YYMM");
      let number = `INV/${yymm}/00001`;
      const last_num = invoices.find((invoice) =>
        new RegExp(`^INV/${yymm}/[0-9]{5}$`).test(invoice.number)
      )?.number;
      if (last_num) {
        const [prefix, yymm, numberPart] = last_num.split("/");
        const incrementedNumber = parseInt(numberPart, 10) + 1;
        const newNumberPart = incrementedNumber.toString().padStart(5, "0");
        number = `${prefix}/${yymm}/${newNumberPart}`;
      }
      setInvoiceData((invData) => ({ ...invData, number }));
    }
    // eslint-disable-next-line
  }, [selected]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevState) => {
      const cpy = _.cloneDeep(prevState);
      _.set(cpy, name, value);
      return cpy;
    });
  };

  const handleBillTo = (opt) => {
    const selected = customers.find((c) => c.id === opt.value);
    setShippingAddresses(selected.shippingAddresses || [selected]);
    setInvoiceData((prevData) => ({
      ...prevData,
      customer: selected,
      items: [
        fields.reduce((o, f) => {
          o[f.key] = "";
          return o;
        }, {}),
      ],
      totalBeforeTax: 0,
      totalAmount: 0,
      totalInWords: 0,
      totalCGST: 0,
      totalSGST: 0,
      totalIGST: 0,
    }));
  };
  const handleShipTo = (opt) => {
    const selected = shippingAddresses.find((c) => c.id === opt.value);
    setInvoiceData((prevData) => ({
      ...prevData,
      shipTo: selected,
      items: [
        fields.reduce((o, f) => {
          o[f.key] = "";
          return o;
        }, {}),
      ],
      totalBeforeTax: 0,
      totalAmount: 0,
      totalInWords: 0,
      totalCGST: 0,
      totalSGST: 0,
      totalIGST: 0,
    }));
  };

  const options = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name}, ${customer.address}, ${customer.city}, ${customer.state}, ${customer.country}`,
  }));

  // Add a new row to the line items
  const addNewRow = () => {
    setInvoiceData((prevData) => ({
      ...prevData,
      items: [
        ...prevData.items,
        fields.reduce((o, f) => {
          o[f.key] = "";
          return o;
        }, {}),
      ],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep?.(invoiceData, setLoading);
  };

  const handleFieldChange = (e, fieldKey, index) => {
    const { name, value } = e.target;
    const items = [...invoiceData.items];
    items[index][name] = value;

    if (["rate", "qty", "gst_rate"].includes(fieldKey)) {
      const rate = parseFloat(items[index].rate || 0);
      const qty = parseFloat(items[index].qty || 0);
      const totalBeforeTax = rate * qty;
      const gstRate = parseFloat(items[index].gst_rate || 0);
      let cgst = 0,
        sgst = 0,
        igst = 0,
        totalAfterTax = 0;

      if (invoiceData.customer.state === currentUser.company.state) {
        cgst = (totalBeforeTax * gstRate) / (2 * 100);
        sgst = (totalBeforeTax * gstRate) / (2 * 100);
        totalAfterTax = totalBeforeTax + cgst + sgst;
      } else {
        igst = (totalBeforeTax * gstRate) / 100;
        totalAfterTax = totalBeforeTax + igst;
      }

      items[index]["total_before_tax"] = totalBeforeTax.toFixed(2);
      items[index]["sgst"] = sgst;
      items[index]["cgst"] = cgst;
      items[index]["igst"] = igst;
      items[index]["total_after_tax"] = totalAfterTax.toFixed(2);
      const getFixed = (k) => (parseFloat(_.sumBy(items, k)) || 0).toFixed(2);
      const totalCGST = getFixed("cgst");
      const totalSGST = getFixed("sgst");
      const totalIGST = getFixed("igst");
      const totalAmount = getFixed("total_after_tax");
      const totalBeforeTaxSum = getFixed("total_before_tax");

      const totalInWords = numberToWords(totalAmount);

      setInvoiceData((prev) => ({
        ...prev,
        items,
        totalBeforeTax: totalBeforeTaxSum,
        totalAmount,
        totalInWords,
        totalCGST,
        totalSGST,
        totalIGST,
      }));
    } else {
      setInvoiceData((prevData) => ({ ...prevData, items }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        {addNewShippingAddress ? (
          <h4>Add new Shipping Address</h4>
        ) : open === "preview" ? (
          <h4> {selected?.number} - Download Preview</h4>
        ) : open === "form" ? (
          <h4> {selected?.id ? "Edit" : "Add New"} Invoice</h4>
        ) : (
          <div></div>
        )}
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setOpen("")}
        ></button>
      </div>
      {addNewShippingAddress ? (
        <AddNewShippingAddress
          setInvoiceData={setInvoiceData}
          setAddNewShippingAddress={setAddNewShippingAddress}
          shippingAddresses={shippingAddresses}
          setShippingAddresses={setShippingAddresses}
        />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} lg={3} className="mb-2">
              <Form.Group controlId="number">
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="number"
                  disabled={true}
                  value={invoiceData.number}
                  onChange={handleChange}
                  placeholder="Enter invoice number"
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-2">
              <Form.Group controlId="date">
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control
                  required
                  disabled={!isEditMode}
                  type="date"
                  name="date"
                  value={invoiceData.date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-2">
              <Form.Group controlId="purchaseOrder.number">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  PO Number
                  <Button
                    className="p-0 text-decoration-none"
                    variant="link"
                    onClick={() => {
                      setInvoiceData((prevState) => {
                        const cpy = _.cloneDeep(prevState);
                        _.set(cpy, "purchaseOrder.number", "Telephonic PO");
                        return cpy;
                      });
                    }}
                  >
                    Autogenerate
                  </Button>
                </Form.Label>
                <Form.Control
                  required
                  disabled={!isEditMode}
                  type="text"
                  name="purchaseOrder.number"
                  value={invoiceData.purchaseOrder.number}
                  onChange={handleChange}
                  placeholder="Enter PO number"
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-2">
              <Form.Group controlId="purchaseOrder.date">
                <Form.Label>PO Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  disabled={!isEditMode}
                  name="purchaseOrder.date"
                  value={invoiceData.purchaseOrder.date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} md={6}>
              <Form.Group controlId="customer.id">
                <Form.Label>Bill To:</Form.Label>
                <Select
                  isDisabled={!isEditMode}
                  name="customer.id"
                  options={options}
                  value={
                    options.find(
                      (option) => option.value === invoiceData.customer?.id
                    ) || null
                  }
                  onChange={(selectedOption) => handleBillTo(selectedOption)}
                  placeholder="Select Customer"
                  required
                />
              </Form.Group>
            </Col>

            {invoiceData.customer.name && (
              <Col xs={12} md={6}>
                <Form.Group controlId="">
                  <Form.Label className="d-flex align-items-center justify-content-between">
                    <span>Ship To</span>
                    {isEditMode && (
                      <Button
                        variant="link"
                        className="p-0 text-decoration-none"
                        onClick={() => setAddNewShippingAddress(true)}
                      >
                        Add new
                      </Button>
                    )}
                  </Form.Label>
                  <Select
                    isDisabled={!isEditMode}
                    options={shippingAddresses.map((v) => ({
                      value: v.id,
                      label: `${v.name}, ${v.address}, ${v.city}, ${v.state}, ${v.country}`,
                    }))}
                    value={
                      shippingAddresses
                        .map((v) => ({
                          value: v.id,
                          label: `${v.name}, ${v.address}, ${v.city}, ${v.state}, ${v.country}`,
                        }))
                        .find(
                          (option) => option.value === invoiceData.shipTo.id
                        ) || null
                    }
                    onChange={(selectedOption) => handleShipTo(selectedOption)}
                    placeholder="Select Customer"
                    required
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          {!!invoiceData.customer?.id && (
            <>
              <div>
                <div className="d-flex justify-content-between mb-2 align-items-center">
                  <div className="fw-bold" style={{ fontSize: 20 }}>
                    Particulars
                  </div>
                  {isEditMode && (
                    <Button
                      variant="link"
                      onClick={addNewRow}
                      size="sm"
                      className="p-0 text-decoration-none"
                    >
                      + Row
                    </Button>
                  )}
                </div>
                <Table bordered hover className="mb-2" size="sm" responsive>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: 70 }}>
                        S No
                      </th>
                      {fields.map((column) => (
                        <th key={column.key} style={column.style}>
                          {column.value}
                        </th>
                      ))}
                      <th style={{ width: 150 }}>Total</th>
                      <th style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.items.map((item, index) => (
                      <tr key={index} style={{ verticalAlign: "middle" }}>
                        <td className="text-center" style={{ minWidth: 50 }}>
                          {index + 1}
                        </td>
                        {fields.map((f) => (
                          <td style={f.style} key={f.key}>
                            {isEditMode ? (
                              <LineItemField
                                disabled={!isEditMode}
                                field={f}
                                item={item}
                                index={index}
                                onChange={(e) =>
                                  handleFieldChange(e, f.key, index)
                                }
                              />
                            ) : (
                              item[f.key]
                            )}
                          </td>
                        ))}
                        <td style={{ padding: 10, width: 150 }}>
                          {formatCurrency(parseFloat(item.total_after_tax))}
                        </td>
                        <td className="text-center">
                          <Button variant="link" className="p-1">
                            <FaTrashAlt
                              className="icons-red"
                              onClick={() => {
                                setInvoiceData((data) => {
                                  let items = [...data.items];
                                  items.splice(index, 1);
                                  return { ...data, items };
                                });
                              }}
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Row className="mb-2">
                <Col lg={3}>
                  <Form.Group controlId="totalBeforeTax">
                    <b>Total Before Tax: </b>
                    {formatCurrency(parseFloat(invoiceData.totalBeforeTax))}
                  </Form.Group>
                </Col>
                {invoiceData.customer.state === currentUser.company.state ? (
                  <>
                    <Col lg={3}>
                      <Form.Group controlId="totalCGST">
                        <b>Total CGST: </b>
                        {formatCurrency(parseFloat(invoiceData.totalCGST))}
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group controlId="totalSGST">
                        <b>Total SGST: </b>
                        {formatCurrency(parseFloat(invoiceData.totalSGST))}
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <Col lg={6}>
                    <Form.Group controlId="totalIGST">
                      <b>Total IGST: </b>
                      {formatCurrency(parseFloat(invoiceData.totalIGST))}
                    </Form.Group>
                  </Col>
                )}

                <Col lg={3}>
                  <Form.Group controlId="totalAmount">
                    <b>Total after Tax: </b>
                    {formatCurrency(parseFloat(invoiceData.totalAmount))}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-0">
                <Col lg={9}>
                  <Form.Group controlId="totalInWords">
                    <b>Total in Words: </b>
                    {invoiceData.totalInWords}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {isEditMode ? (
            <div className="d-flex justify-content-end gap-4">
              {invoiceData?.id && (
                <Button
                  variant="secondary"
                  onClick={() => setIsEditMode(false)}
                >
                  Cancel
                </Button>
              )}
              <CustomButton
                variant="primary"
                type="submit"
                className="float-end"
                loading={loading}
              >
                {loading ? "Submitting" : "Submit"}
              </CustomButton>
            </div>
          ) : (
            <div className="d-flex gap-4 float-end">
              <Button variant="primary" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            </div>
          )}
        </Form>
      )}
    </>
  );
}
