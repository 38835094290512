import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { where } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCompany, createUser, fetchDocuments } from "../utils/firebase";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoader, setAuthLoader] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let isInitial = false;
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthLoader(true);
      if (isInitial) {
        isInitial = false; // Avoid navigation after the initial check
        return;
      }
      if (user) {
        fetchDocuments("company", [where("email", "==", user.email)])
          .then((companies) => {
            if (companies.length === 0) return;
            const company = companies[0]; // Assuming `owner.id` is unique
            setCurrentUser({
              ...company.owner,
              company_id: company.id,
              company,
            });
          })
          .finally(() => setAuthLoader(false));
      } else {
        setCurrentUser(null);
        setAuthLoader(false);
      }
    });

    return () => {
      unsubscribe();
      isInitial = true; // Reset when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const signUp = async (values) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;
      const company_id = await createCompany(values.company);
      const params = {
        id: user.uid,
        email: values.email,
        company_id,
        name: values.name,
      };

      createUser({ ...params }).then((user) => {
        setCurrentUser(params);
        setAuthLoader(false);
      });
    } catch (error) {
      throw error;
    }
  };

  const signIn = async (values) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    await auth.signOut();
    navigate("/");
  };

  async function createUserWithoutAffectingSession(
    auth,
    newUserEmail,
    newUserPassword
  ) {
    const currentUser = auth.currentUser;
    const originalUserCredentials = currentUser
      ? { email: currentUser.email, password: "Zap@123" } // Store the current user's credentials securely
      : null;

    // Log out the current user
    if (currentUser) await signOut(auth);

    // Create the new user
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      newUserEmail,
      newUserPassword
    );

    const newUser = userCredential.user;

    await signInWithEmailAndPassword(
      auth,
      originalUserCredentials.email,
      originalUserCredentials.password
    );
    return newUser;
  }

  const value = {
    currentUser,
    setCurrentUser,
    signUp,
    signIn,
    signOut,
    authLoader,
    createUserWithoutAffectingSession,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
