import { Container } from "react-bootstrap";
import { useAuth } from "../context/user";

export default function SignedHome() {
  const { currentUser } = useAuth();
  return (
    <Container className="mt-5">
      <h2>Hi, {currentUser?.name || "User"}!</h2>
      <p>Welcome back!</p>
    </Container>
  );
}
