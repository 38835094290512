import { useRef, useState } from "react";
import { Button, Modal, Offcanvas, Overlay } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaPowerOff, FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import AuthForm from "../auth";
import { useAuth } from "../context/user";

const styles = {
  wrapper: {
    textAlign: "left",
  },
  heading: {
    fontSize: "40px",
    lineHeight: "54px",
    fontWeight: 500,
    margin: "0 0 15px",
  },
  paragraph: {
    fontSize: "16px",
    lineHeight: "31.85px",
    fontWeight: 400,
    margin: "0 0 25px",
  },
  buttonContainer: {
    display: "inline-block",
  },
  button: {
    display: "inline-block",
    fontSize: 14,
    color: "#ffffff",
    backgroundColor: "#007bff", // Adjust color for ZapInvoice branding if needed
    padding: "4px 16px",
    borderRadius: "16px",
    textDecoration: "none",
    fontWeight: 500,
    marginLeft: 10,
  },
};

function NavItem({ item, toggleOffcanvas }) {
  return item.link ? (
    <Link
      style={{ color: "var(--bs-navbar-color)" }}
      className="nav-link"
      to={item.link}
      key={item.link}
      onClick={() => toggleOffcanvas?.()}
    >
      {item.icon ? (
        <div className="d-flex gap-3 align-items-center">
          <span>{item.icon}</span>
          <span className="d-block d-md-none">{item.text}</span>
        </div>
      ) : (
        item.text
      )}
    </Link>
  ) : (
    <Button
      key={item.link}
      className="nav-link"
      style={
        item.type === "button"
          ? styles.button
          : {
              backgroundColor: "transparent",
              border: "none",
              color: "#444",
            }
      }
      onClick={item.onClick}
    >
      {item.icon ? (
        <div className="d-flex gap-3 align-items-center">
          <span>{item.icon}</span>
          <span className="d-block d-md-none">{item.text}</span>
        </div>
      ) : (
        item.text
      )}
    </Button>
  );
}

export default function Appbar() {
  const { signOut, currentUser, authLoader } = useAuth();
  const [showPopover, setShowPopover] = useState(false); // Control popover visibility
  const [open, setOpen] = useState(""); // Control popover visibility
  const target = useRef(null); // Reference to the user icon trigger

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleMouseEnter = () => setShowPopover(true);
  const handleMouseLeave = () => setShowPopover(false);

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const navItems = authLoader
    ? []
    : currentUser
    ? [
        ...(currentUser.is_csa
          ? [{ link: "/companies", text: "Companies", align: "left" }]
          : [
              { link: "/customers", text: "Customers", align: "left" },
              { link: "/invoices", text: "Invoices", align: "left" },
            ]),
        {
          onClick: signOut,
          icon: <FaPowerOff />,
          align: "right",
          text: "Signout",
        },
        {
          link: "/profile",
          icon: <FaUserAlt />,
          align: "right",
          text: "Profile",
        },
      ]
    : [
        {
          onClick: () => setOpen("signin"),
          text: "Sign In",
          align: "right",
          type: "button",
        },
        // {
        //   onClick: () => setOpen("signup"),
        //   text: "Sign Up",
        //   align: "right",
        //   type: "button",
        // },
      ];

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Nav className="me-auto">
            <Link className="nav-link" to="/">
              <img src="/images/logo_placeholder.png" alt="logo" width={40} />
            </Link>
          </Nav>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={toggleOffcanvas}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
            <Nav className="me-auto">
              {navItems
                .filter((item) => item.align === "left")
                .map((item, index) => (
                  <NavItem item={item} key={index} />
                ))}
            </Nav>
            <Nav className="justify-content-end">
              {navItems
                .filter((item) => item.align === "right")
                .map((item, index) =>
                  item.overlay ? (
                    <div ref={target} style={{ cursor: "pointer" }} key={index}>
                      <NavItem item={item} />
                      <Overlay
                        show={showPopover}
                        target={target.current}
                        placement="bottom"
                      >
                        {item.overlay}
                      </Overlay>
                    </div>
                  ) : (
                    <NavItem item={item} key={index} />
                  )
                )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Offcanvas Drawer for Mobile */}
      <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav
            className="flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            {/* Left-aligned items at the top */}
            <div>
              {navItems
                .filter((item) => item.align === "left")
                .map((item, index) => (
                  <NavItem
                    item={item}
                    key={index}
                    toggleOffcanvas={toggleOffcanvas}
                  />
                ))}
            </div>
            <div>
              {/* Right-aligned items at the bottom */}
              {navItems
                .filter((item) => item.align === "right")
                .map((item, index) =>
                  item.overlay ? (
                    <div
                      ref={target}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <NavItem item={item} toggleOffcanvas={toggleOffcanvas} />
                      <Overlay
                        show={showPopover}
                        target={target.current}
                        placement="bottom"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {item.overlay}
                      </Overlay>
                    </div>
                  ) : (
                    <NavItem item={item} key={index} />
                  )
                )}
            </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={["signin", "signup"].includes(open)}
        onHide={() => setOpen("")}
        centered
        fullscreen="lg-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render the AuthForm component inside the modal */}
          <AuthForm
            type={open}
            callback={() => {
              setOpen("");
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
