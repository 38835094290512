import React from "react";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../context/user";
import SignedHome from "./signed";
import UnsignedHome from "./unsigned";

const HomeIndex = () => {
  const { currentUser, authLoader } = useAuth();
  return (
    <div>
      {authLoader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Spinner />
        </div>
      ) : currentUser ? (
        <SignedHome />
      ) : (
        <UnsignedHome />
      )}
    </div>
  );
};

export default HomeIndex;
