import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import React from "react";
import { Button, Col, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import LocationSubForm from "../custom/locationSubForm";
import { getGSTPattern } from "../utils/common";

// Step 1: Form Step for entering the Invoice Details
export default function FormStep({ isEditMode, companyData, setCompanyData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevState) => {
      const cpy = _.cloneDeep(prevState);
      _.set(cpy, name, value);
      return cpy;
    });
  };
  return (
    <Tab.Container defaultActiveKey="customerDetails">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="customerDetails">General Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bankDetails">Bank Account Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="termsAndConditions">
                Terms and Conditions
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="customerDetails">
              <h3>General Details</h3>
              <Row>
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Form.Group controlId="name">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="name"
                      value={companyData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="email"
                      name="email"
                      value={companyData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Form.Group controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="mobile"
                      value={companyData.mobile}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Form.Group controlId="gstin">
                    <Form.Label>GSTIN</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="gstin"
                      pattern={getGSTPattern(companyData.state)}
                      title="Please enter valid GST"
                      value={companyData.gstin}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <LocationSubForm
                parentData={companyData}
                setParentData={setCompanyData}
                isEditMode={isEditMode}
              />
              <Row>
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Form.Group controlId="owner.name">
                    <Form.Label>Owner's Name</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="owner.name"
                      value={companyData.owner.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab.Pane>

            <Tab.Pane eventKey="bankDetails">
              <h3>Bank Account Details</h3>
              <Row className="mb-4">
                <Col xs={12} md={6} lg={4} className="mb-2">
                  <Form.Group controlId="bankName">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="bankName"
                      value={companyData.bank?.bankName}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setCompanyData((prevState) => {
                          const updatedData = { ...prevState };
                          if (!updatedData.bank) updatedData.bank = {};
                          updatedData.bank[name] = value;
                          return updatedData;
                        });
                      }}
                      placeholder="Enter bank name"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-2">
                  <Form.Group controlId="accountNumber">
                    <Form.Label>Bank Account Number</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="accountNumber"
                      value={companyData.bank?.accountNumber}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setCompanyData((prevState) => {
                          const updatedData = { ...prevState };
                          if (!updatedData.bank) updatedData.bank = {};
                          updatedData.bank[name] = value;
                          return updatedData;
                        });
                      }}
                      placeholder="Enter bank account number"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-2">
                  <Form.Group controlId="ifscCode">
                    <Form.Label>IFSC Code</Form.Label>
                    <Form.Control
                      required
                      disabled={!isEditMode}
                      type="text"
                      name="ifscCode"
                      value={companyData.bank?.ifscCode}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setCompanyData((prevState) => {
                          const updatedData = { ...prevState };
                          if (!updatedData.bank) updatedData.bank = {};
                          updatedData.bank[name] = value;
                          return updatedData;
                        });
                      }}
                      placeholder="Enter IFSC code"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="termsAndConditions">
              <h3>Terms and Conditions</h3>
              <Row className="mb-4">
                <Col xs={12} className="mb-2">
                  {(companyData.terms || []).map((term, index) => (
                    <InputGroup key={term} className="mb-3">
                      <Form.Control
                        type="text"
                        disabled={!isEditMode}
                        placeholder={`Term ${index + 1}`}
                        value={term}
                        onChange={(e) => {
                          let cpy = _.cloneDeep(companyData);
                          cpy.terms[index] = e.target.value;
                          setCompanyData(cpy);
                        }}
                      />
                      {isEditMode && (
                        <Button
                          variant="danger"
                          onClick={() => {
                            let cpy = _.cloneDeep(companyData);
                            cpy.terms.splice(index, 0);
                            setCompanyData(cpy);
                          }}
                          disabled={companyData.terms.length === 1} // Prevent deleting the last row
                        >
                          <FaTrash />
                        </Button>
                      )}
                    </InputGroup>
                  ))}
                  {isEditMode && (
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        onClick={() => {
                          let cpy = _.cloneDeep(companyData);
                          cpy.terms.push("");
                          setCompanyData(cpy);
                        }}
                        className="mt-2"
                      >
                        <FaPlus /> Add Term
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
