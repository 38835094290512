import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchDocuments } from "../utils/firebase";
import { useAuth } from "./user";
import { where } from "firebase/firestore";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [invoices, setInvoices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [dataLoader, setDataLoader] = useState(0);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser?.id || currentUser?.is_csa) return;
    setDataLoader((l) => l + 1);
    fetchDocuments("invoice", [
      where("company_id", "==", currentUser.company_id),
    ]).then((invoices) => {
      setInvoices(invoices);
      setDataLoader((l) => l - 1);
    });

    setDataLoader((l) => l + 1);
    fetchDocuments("customer", [
      where("company_id", "==", currentUser.company_id),
    ]).then((customers) => {
      setCustomers(customers);
      setDataLoader((l) => l - 1);
    });
  }, [currentUser]);

  const value = {
    invoices,
    setInvoices,
    customers,
    setCustomers,
    dataLoader,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
