import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchDocuments } from "../utils/firebase";
import { useAuth } from "./user";

const CsaDataContext = createContext();

export const useCsaData = () => useContext(CsaDataContext);

export const CsaDataProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [dataLoader, setDataLoader] = useState(0);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser?.id || !currentUser.is_csa) return;

    setDataLoader((l) => l + 1);

    fetchDocuments("company")
      .then((companies) =>
        setCompanies(companies.filter((c) => !c.owner?.is_csa))
      )
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setDataLoader((l) => l - 1));
  }, [currentUser]);

  const value = {
    companies,
    setCompanies,
    dataLoader,
  };
  return (
    <CsaDataContext.Provider value={value}>{children}</CsaDataContext.Provider>
  );
};
