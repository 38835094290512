import _ from "lodash";
import { numberToWords } from "../utils/common";

const invoiceLineItemfields = [
  {
    key: "description",
    value: "Description",
    type: "text",
    style: { minWidth: 200 },
  },
  {
    key: "hsn_code",
    value: "HSN",
    type: "text",
    style: { minWidth: 100 },
  },
  {
    key: "rate",
    value: "Rate",
    type: "currency",
    style: { minWidth: 150 },
    onChange: (newData) => {
      const totalAmount = _.sumBy(newData.items, (item) => {
        const { rate, qty, gst_rate } = { ...item };
        return (
          parseFloat(rate || 0) *
          parseFloat(qty || 0) *
          (1 + parseFloat(gst_rate || 0) / 100)
        );
      });
      const totalInWords = numberToWords(totalAmount);
      return {
        totalAmount,
        totalInWords,
      };
    },
  },
  {
    key: "qty",
    value: "Qty",
    type: "number",
    style: { minWidth: 100 },
  },
  {
    key: "total_before_tax",
    value: "Taxable Amt",
    type: "currency_text",
    style: { minWidth: 150 },
  },
  {
    key: "gst_rate",
    value: "GST Rate",
    type: "percentage",
    style: { minWidth: 150 },
  },
];

export { invoiceLineItemfields };
