import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Appbar from "./appbar";
import { AuthProvider } from "./context/user";
import CustomerIndex from "./customer";
import HomeIndex from "./home";
import InvoiceIndex from "./invoice";
import { DataProvider } from "./context/data";
import AuthForm from "./auth";
import Profile from "./auth/profile";
import { CsaDataProvider } from "./context/csa";
import CompanyIndex from "./company";

const routes = [
  { key: "customer", path: "/customers", element: <CustomerIndex /> },
  { key: "invoice", path: "/invoices", element: <InvoiceIndex /> },
  { key: "home", path: "/", element: <HomeIndex /> },
  { key: "auth", path: "/auth", element: <AuthForm /> },
  { key: "profile", path: "/profile", element: <Profile /> },
  { key: "company", path: "/companies", element: <CompanyIndex /> },
];
const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <DataProvider>
          <CsaDataProvider>
            <Appbar />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </CsaDataProvider>
        </DataProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
export default App;
