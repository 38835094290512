import { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Table,
  Tooltip,
  Pagination,
} from "react-bootstrap";

const ITEMS_PER_PAGE = 10;

export default function CustomerTable({ customers, actions, onEdit }) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(customers.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginated = customers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div>
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>GSTIN</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginated.length > 0 ? (
            paginated.map((customer, index) => (
              <tr key={index}>
                <td>
                  <Button
                    variant="link"
                    style={{ textDecoration: "none", textAlign: "left" }}
                    size="sm"
                    onClick={() => onEdit(customer)}
                  >
                    {customer.name}
                  </Button>
                </td>
                <td
                  style={{ maxWidth: 200 }}
                >{`${customer.address}, ${customer.city}, ${customer.state}, ${customer.country}`}</td>

                <td>{customer.email}</td>
                <td>{customer.mobile}</td>
                <td>{customer.gstin}</td>
                {actions.length !== 0 && (
                  <td className="text-center">
                    {actions.map((action) => (
                      <OverlayTrigger
                        key={action.key}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${action.key}`}>
                            {action.label}
                          </Tooltip>
                        }
                      >
                        <Button
                          key={action.key}
                          variant="link"
                          size="sm"
                          onClick={() => action.onClick(customer)}
                        >
                          {action.icon}
                        </Button>
                      </OverlayTrigger>
                    ))}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No customers available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {totalPages > 1 && (
        <Pagination>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}
