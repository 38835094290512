import moment from "moment";
function dateFormat(dt, format = "DD MMM YYYY") {
  return dt ? moment.utc(dt).local().format(format) : "";
}

const stateUpdator = (selected, newEntity, setEntities) => {
  setEntities((entities) => {
    let entityList = [...entities];
    if (selected) {
      entityList = entityList.map((entity) =>
        entity.id === newEntity.id ? newEntity : entity
      );
    } else {
      entityList = [newEntity, ...entityList];
    }
    return entityList;
  });
};

function numberToWords(num) {
  const words = [];
  if (parseFloat(num) === 0) return "-";

  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  // Function to convert numbers less than 1000 to words
  const convertHundreds = (n) => {
    if (n > 99) {
      words.push(ones[Math.floor(n / 100)]);
      words.push("Hundred");
      n %= 100;
    }

    if (n > 19) {
      words.push(tens[Math.floor(n / 10)]);
      if (n % 10 > 0) {
        words.push(ones[n % 10]);
      }
    } else if (n > 0) {
      words.push(ones[n]);
    }
  };

  // Handle whole number part
  let wholeNumber = Math.floor(num);

  // Crores
  let crore = Math.floor(wholeNumber / 10000000);
  if (crore > 0) {
    convertHundreds(crore);
    words.push("Crore");
  }

  // Lakhs
  let lakh = Math.floor((wholeNumber % 10000000) / 100000);
  if (lakh > 0) {
    convertHundreds(lakh);
    words.push("Lakh");
  }

  // Thousands
  let thousand = Math.floor((wholeNumber % 100000) / 1000);
  if (thousand > 0) {
    convertHundreds(thousand);
    words.push("Thousand");
  }

  // Hundreds
  let hundred = Math.floor((wholeNumber % 1000) / 100);
  if (hundred > 0) {
    convertHundreds(hundred);
    words.push("Hundred");
  }
  // // Hundreds
  let ten = Math.floor(wholeNumber % 100);
  if (ten > 0) {
    convertHundreds(ten);
  }
  words.push("Rupees");
  // Handle fractional part (if any)
  const fractionalPart = Math.round((num - wholeNumber) * 100);
  if (fractionalPart > 0) {
    words.push("and");
    if (fractionalPart < 20) {
      words.push(ones[fractionalPart]);
      words.push("Paise");
    } else {
      words.push(tens[Math.floor(fractionalPart / 10)]);
      if (fractionalPart % 10 > 0) {
        words.push(ones[fractionalPart % 10]);
      }
      words.push("Paise");
    }
  }
  words.push("Only");

  return words.join(" ").trim();
}

function formatCurrency(value) {
  if (value <= 0 || isNaN(value) || !isFinite(value)) return "-";
  return value.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
}

function getGSTPattern(stateCode) {
  const _map = {
    JK: "01",
    HP: "02",
    PB: "03",
    CH: "04",
    UT: "05",
    HR: "06",
    DL: "07",
    RJ: "08",
    UP: "09",
    BR: "10",
    SK: "11",
    AR: "12",
    NL: "13",
    MN: "14",
    MZ: "15",
    TR: "16",
    ML: "17",
    AS: "18",
    WB: "19",
    JH: "20",
    OR: "21",
    CT: "22",
    MP: "23",
    GJ: "24",
    DH: "26",
    MH: "27",
    KA: "29",
    GA: "30",
    LD: "31",
    KL: "32",
    TN: "33",
    PY: "34",
    ANI: "35",
    TG: "36",
    AP: "37",
    LA: "38",
  };
  return `^${_map[stateCode]}[A-Z]{5}[0-9]{4}[A-Z][0-9][A-Z][0-9]$`;
}

export {
  dateFormat,
  numberToWords,
  stateUpdator,
  formatCurrency,
  getGSTPattern,
};
