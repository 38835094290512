import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../context/user";
import CustomButton from "../../custom/button";
import LocationSubForm from "../../custom/locationSubForm";
import { getGSTPattern } from "../../utils/common";
import { createOrUpdate } from "../../utils/firebase";
export default function Profile() {
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    mobile: "",
    gstin: "",
    country: "",
    state: "",
    city: "",
    address: "",
    bank: {
      accountNumber: "",
      ifscCode: "",
    },
    terms: [],
  });

  useEffect(() => {
    setCustomerData(
      currentUser?.company || {
        name: "",
        email: "",
        mobile: "",
        gstin: "",
        country: "",
        state: "",
        city: "",
        address: "",
        bank: {
          accountNumber: "",
          ifscCode: "",
        },
        terms: [],
      }
    );
  }, [currentUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setCurrentUser({ ...currentUser, company: customerData });
    createOrUpdate("company", customerData).then(() => {
      toast(`Profile edited successfully`);
      setLoading(false);
      setIsEditMode(false);
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevState) => {
      const cpy = _.cloneDeep(prevState);
      _.set(cpy, name, value);
      return cpy;
    });
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={handleSubmit}>
        <Tab.Container defaultActiveKey="customerDetails">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="customerDetails">
                    General Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bankDetails">
                    Bank Account Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="termsAndConditions">
                    Terms and Conditions
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="customerDetails">
                  <h3>General Details</h3>
                  <Row>
                    <Col xs={12} md={6} lg={3} className="mb-2">
                      <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="name"
                          value={customerData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-2">
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="email"
                          name="email"
                          value={customerData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-2">
                      <Form.Group controlId="mobile">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="mobile"
                          value={customerData.mobile}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-2">
                      <Form.Group controlId="gstin">
                        <Form.Label>GSTIN</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="gstin"
                          pattern={getGSTPattern(customerData.state)}
                          title="Please enter valid GST"
                          value={customerData.gstin}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <LocationSubForm
                    parentData={customerData}
                    setParentData={setCustomerData}
                    isEditMode={isEditMode}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="bankDetails">
                  <h3>Bank Account Details</h3>
                  <Row className="mb-4">
                    <Col xs={12} md={6} lg={6} className="mb-2">
                      <Form.Group controlId="bankName">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="bankName"
                          value={customerData.bank?.bankName}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setCustomerData((prevState) => {
                              const updatedData = { ...prevState };
                              if (!updatedData.bank) updatedData.bank = {};
                              updatedData.bank[name] = value; // Update the bank key inside customerData
                              return updatedData;
                            });
                          }}
                          placeholder="Enter bank name"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="mb-2">
                      <Form.Group controlId="accountNumber">
                        <Form.Label>Bank Account Number</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="accountNumber"
                          value={customerData.bank?.accountNumber}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setCustomerData((prevState) => {
                              const updatedData = { ...prevState };
                              if (!updatedData.bank) updatedData.bank = {};
                              updatedData.bank[name] = value; // Update the bank key inside customerData
                              return updatedData;
                            });
                          }}
                          placeholder="Enter bank account number"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="mb-2">
                      <Form.Group controlId="ifscCode">
                        <Form.Label>IFSC Code</Form.Label>
                        <Form.Control
                          required
                          disabled={!isEditMode}
                          type="text"
                          name="ifscCode"
                          value={customerData.bank?.ifscCode}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setCustomerData((prevState) => {
                              const updatedData = { ...prevState };
                              if (!updatedData.bank) updatedData.bank = {};
                              updatedData.bank[name] = value; // Update the bank key inside customerData
                              return updatedData;
                            });
                          }}
                          placeholder="Enter IFSC code"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {isEditMode ? (
                    <div className="d-flex justify-content-end gap-4">
                      <Button
                        variant="secondary"
                        onClick={() => setIsEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <CustomButton
                        variant="primary"
                        type="submit"
                        className="float-end"
                        loading={loading}
                      >
                        {loading ? "Submitting" : "Submit"}
                      </CustomButton>
                    </div>
                  ) : (
                    <div className="d-flex gap-4 float-end">
                      <Button
                        variant="primary"
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="termsAndConditions">
                  <h3>Terms and Conditions</h3>
                  <Row className="mb-4">
                    <Col xs={12} className="mb-2">
                      {customerData.terms.map((term, index) => (
                        <InputGroup key={term} className="mb-3">
                          <Form.Control
                            required
                            type="text"
                            disabled={!isEditMode}
                            placeholder={`Term ${index + 1}`}
                            value={term}
                            onChange={(e) => {
                              let cpy = _.cloneDeep(customerData);
                              cpy.terms[index] = e.target.value;
                              setCustomerData(cpy);
                            }}
                          />
                          {isEditMode && (
                            <Button
                              variant="danger"
                              onClick={() => {
                                let cpy = _.cloneDeep(customerData);
                                cpy.terms.splice(index, 0);
                                setCustomerData(cpy);
                              }}
                              disabled={customerData.terms.length === 1} // Prevent deleting the last row
                            >
                              <FaTrash />
                            </Button>
                          )}
                        </InputGroup>
                      ))}
                      {isEditMode && (
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="primary"
                            onClick={() => {
                              let cpy = _.cloneDeep(customerData);
                              cpy.terms.push("");
                              setCustomerData(cpy);
                            }}
                            className="mt-2"
                          >
                            <FaPlus /> Add Term
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {isEditMode ? (
                    <div className="d-flex justify-content-end gap-4">
                      <Button
                        variant="secondary"
                        onClick={() => setIsEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <CustomButton
                        variant="primary"
                        type="submit"
                        className="float-end"
                        loading={loading}
                      >
                        {loading ? "Submitting" : "Submit"}
                      </CustomButton>
                    </div>
                  ) : (
                    <div className="d-flex gap-4 float-end">
                      <Button
                        variant="primary"
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>

          <ToastContainer />
        </Tab.Container>
      </Form>
    </Container>
  );
}
