import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import {
  FaApple,
  FaFacebookF,
  FaGooglePlay,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { LightAccountForm } from "../auth";
import "./unsigned.css";

const styles = {
  wrapper: {
    textAlign: "left",
  },
  heading: {
    fontSize: "40px",
    lineHeight: "54px",
    fontWeight: 500,
    margin: "0 0 15px",
  },
  paragraph: {
    fontSize: "16px",
    lineHeight: "31.85px",
    fontWeight: 400,
    margin: "0 0 25px",
  },
  buttonContainer: {
    display: "inline-block",
  },
  button: {
    display: "inline-block",
    fontSize: "16px",
    color: "#ffffff",
    backgroundColor: "#007bff", // Adjust color for ZapInvoice branding if needed
    padding: "12px 24px",
    borderRadius: "24px",
    textDecoration: "none",
    fontWeight: 500,
  },
};

const HeroComponent = ({ setOpen }) => {
  return (
    <section className="hero py-4" style={{ backgroundColor: "#f0f0f0" }}>
      <Container className="d-flex justify-content-between align-items-center py-3 px-5">
        <Row className="align-items-center justify-content-center">
          {/* Left Column: Content */}
          <Col md={6} className="text-center text-md-start px-4">
            <h1>ZapInvoice — Billing Simplified</h1>
            <p className="lead">
              Focus on your business while we simplify your billing. Our
              software is intuitive and budget-friendly, with transparent
              pricing and seamless integrations to keep your workflow smooth and
              stress-free.
            </p>
            <div style={styles.buttonContainer}>
              <Button
                style={styles.button}
                onClick={() => setOpen("getStarted")}
              >
                Contact Us
              </Button>
            </div>
          </Col>

          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center"
          >
            <Image
              width={350}
              src="/images/hero_bg.png"
              alt="Hero Background"
              rounded
              fluid
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const ValuesComponent = () => (
  <section className="values py-4 bg-white">
    <Container>
      <Row className="text-center">
        {/* Ease of Use */}
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm hover-card">
            <Card.Body>
              <Card.Title>Ease of Use</Card.Title>
              <Card.Text>
                Our software is designed with simplicity in mind, so you can
                focus on running your business.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Price Point */}
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm hover-card">
            <Card.Body>
              <Card.Title>Affordable Pricing</Card.Title>
              <Card.Text>
                Get all the essential features without breaking the bank.
                Transparent pricing with no hidden fees.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Integrations */}
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm hover-card">
            <Card.Body>
              <Card.Title>Seamless Integrations</Card.Title>
              <Card.Text>
                Integrate effortlessly with your existing tools and streamline
                your workflow for better productivity.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
);

const MissionComponent = () => (
  <section className="values py-4 bg-white">
    <Container>
      <Row className="text-center fs-2" style={{ margin: "0 12vw" }}>
        At ZapInvoice, our mission is to empower businesses by simplifying their
        invoicing process, making it efficient, cost-effective, and seamlessly
        integrated for greater productivity and financial clarity.
      </Row>
    </Container>
  </section>
);

const data = [
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-1-1.webp",
    alt: "Ease of Use",
    title: "Ease of Use",
    text: "ZapInvoice offers an intuitive, user-friendly interface that allows quick onboarding and easy access to key features on both mobile and desktop.",
  },
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-1.webp",
    alt: "Affordable Pricing",
    title: "Affordable Pricing",
    text: "With transparent and budget-friendly pricing options, ZapInvoice makes professional invoicing accessible to businesses of all sizes.",
  },
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-4.webp",
    alt: "Seamless Integrations",
    title: "Seamless Integrations",
    text: "Integrate ZapInvoice effortlessly with various popular tools and platforms, enhancing productivity and reducing data entry tasks.",
  },
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-3.webp",
    alt: "Secure Invoicing",
    title: "Secure Invoicing",
    text: "With industry-grade encryption and secure data handling practices, ZapInvoice ensures your invoicing data remains safe and confidential.",
  },
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-2.webp",
    alt: "Automation",
    title: "Automation",
    text: "Automate recurring invoices, reminders, and payment processing, allowing you to focus more on business growth and less on administrative tasks.",
  },
  {
    img: "https://manufapp.com/wp-content/uploads/2022/03/quality-assurance-5.webp",
    alt: "Dedicated Support",
    title: "Dedicated Support",
    text: "Our responsive support team is ready to assist with any issues or questions, ensuring smooth use and maximum value from ZapInvoice.",
  },
];

const WhyZapInvoiceComponent = () => {
  return (
    <section className="values py-4 bg-white">
      <Container>
        <Row className="text-center">
          {data.map((feature, index) => (
            <Col md={4}>
              <Card key={index} className="my-3">
                <Row className="align-items-center">
                  <Col sm={4} className="text-center">
                    <img
                      src={feature.img}
                      alt={feature.alt}
                      width={70}
                      height={70}
                    />
                  </Col>
                  <Col sm={8}>
                    <Card.Body className="ps-0">
                      <Card.Title
                        className="mb-2"
                        style={{
                          fontSize: 18,
                          color: "#262626",
                          fontWeight: 800,
                        }}
                      >
                        {feature.title}
                      </Card.Title>
                      <Card.Text style={{ fontSize: 12, color: "#666" }}>
                        {feature.text}
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

const GetStartedComponent = ({ setOpen }) => {
  return (
    <section className="values py-4 bg-white">
      <Container>
        <Row className="text-center">
          <Col md={6}>
            {" "}
            <div style={styles.wrapper}>
              <h3 style={styles.heading}>Ready to Simplify Your Invoicing?</h3>
              <p style={styles.paragraph}>
                Discover why businesses trust ZapInvoice to streamline their
                invoicing process with ease and transparency. No long-term
                commitments—experience the full suite of Pro features today!
              </p>
              <div style={styles.buttonContainer}>
                <Button
                  style={styles.button}
                  onClick={() => setOpen("getStarted")}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </Col>
          <Col md={6} className="px-4">
            <img src="/images/e_invoice.png" alt="Invoice" width={500} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Footer = () => {
  return (
    <footer
      className="footer bg-dark text-white py-4"
      style={{ marginBottom: -25 }}
    >
      <Container>
        <Row className="text-center text-md-left">
          {/* Section: About & Links */}
          <Col md={3} className="mb-3">
            <h6>Company</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#about" className="text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="#contact" className="text-white">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#faq" className="text-white">
                  FAQ
                </a>
              </li>
            </ul>
          </Col>

          {/* Section: Legal Links */}
          <Col md={3} className="mb-3">
            <h6>Legal</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#privacy" className="text-white">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#terms" className="text-white">
                  Terms of Use
                </a>
              </li>
            </ul>
          </Col>

          {/* Section: App Links */}
          <Col md={3} className="mb-3">
            <h6>Get the App</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#appstore" className="text-white d-inline-block mb-2">
                  <FaApple size={24} /> App Store
                </a>
              </li>
              <li>
                <a href="#playstore" className="text-white d-inline-block">
                  <FaGooglePlay size={24} /> Google Play
                </a>
              </li>
            </ul>
          </Col>

          {/* Section: Social Media */}
          <Col md={3} className="mb-3">
            <ul className="list-unstyled">
              <h6>Follow Us</h6>
              <li>
                <a href="#facebook" className="text-white d-inline-block mb-2">
                  <FaFacebookF size={24} /> Facebook
                </a>
              </li>
              <li>
                <a href="#twitter" className="text-white d-inline-block">
                  <FaTwitter size={24} /> X (Twitter)
                </a>
              </li>
              <li>
                <a href="#linkedin" className="text-white d-inline-block mb-2">
                  <FaLinkedinIn size={24} /> LinkedIn
                </a>
              </li>
              <li>
                <a href="#youtube" className="text-white d-inline-block">
                  <FaYoutube size={24} /> Youtube
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">
              &copy; 2024 Nexif Technologies. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default function UnsignedHome() {
  const [open, setOpen] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({});

  const handleAuth = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    // setError("");

    try {
      toast(
        "Thanks for contacting us. We will be contacting you shortly on the email id."
      );
    } catch (error) {
      toast(error.message);
    } finally {
      setButtonLoading(false);
      setOpen("");
    }
  };
  return (
    <div>
      <HeroComponent setOpen={setOpen} />
      <ValuesComponent />
      <MissionComponent />
      <WhyZapInvoiceComponent />
      <GetStartedComponent setOpen={setOpen} />
      <Footer />

      <Modal show={open === "getStarted"} onHide={() => setOpen("")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us for a free trial now!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LightAccountForm
            handleAuth={handleAuth}
            buttonLoading={buttonLoading}
            formDetails={formDetails}
            setFormDetails={setFormDetails}
          />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}
