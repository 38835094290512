import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

import { pluralize } from "inflection";
// Function to check if a company exists and create it if not
const createCompany = async (companyName) => {
  try {
    const companiesRef = collection(db, "companies");
    const q = query(companiesRef, where("name", "==", companyName));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      throw new Error("Company already exists.");
    }
    const params = { name: companyName };

    const companyDocRef = await addDoc(companiesRef, {
      ...params,
      createdAt: serverTimestamp(),
    });
    return companyDocRef.id;
  } catch (error) {
    throw error;
  }
};

// Function to create a user with company ID
const createUser = async (params) => {
  let ref = null;
  try {
    const userDocRef = doc(db, "users", params.id);
    ref = await setDoc(userDocRef, { ...params, createdAt: serverTimestamp() });
  } catch (error) {
    throw error;
  }
  return ref;
};

const fetchDocument = async (colName, id) => {
  let user = {};
  try {
    const userDocRef = doc(db, pluralize(colName), id);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      user = { id, ...userData };
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching user details:", error.message);
  }
  return user;
};

const fetchDocuments = async (collectionName, constraints = []) => {
  let ref = query(collection(db, pluralize(collectionName)));
  ref = constraints ? query(ref, ...constraints) : ref;
  const data = await getDocs(query(ref, orderBy("createdAt", "desc")));
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

const createOrUpdate = async (colName, params) => {
  try {
    let ret = null;
    if (!!params.id) {
      const { id, ...rest } = { ...params };
      const refDoc = doc(db, pluralize(colName), id);
      ret = await updateDoc(refDoc, { ...rest, updatedAt: serverTimestamp() });
    } else {
      const refDoc = collection(db, pluralize(colName));
      ret = await addDoc(refDoc, { ...params, createdAt: serverTimestamp() });
    }
    return !!params.id ? params : fetchDocument(colName, ret.id);
  } catch (error) {
    console.error("Error writing document: ", error);
  }
};

export {
  createCompany,
  createOrUpdate,
  createUser,
  fetchDocument,
  fetchDocuments,
};
